* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

@font-face {
  font-family: 'Inter';
  src: url(./fonts/Inter-Regular.ttf);
}

@font-face {
  font-family: 'InterLight';
  src: url(./fonts/Inter-Light.ttf);
}

@font-face {
  font-family: 'InterBold';
  src: url(./fonts/Inter-Bold.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: 'InterBold';
  font-size: 8vw;
}
h2 {
  font-size: 4vw;
  font-family: 'InterLight';
}

h3 {

  font-size: 4vw;
  font-family: 'InterLight';
}

p {
  font-size: 1.5vw;
  font-family: 'InterLight'
}

@media all and (max-width: 480px){
  h1 {
    font-size: 10vw;
  }

  h2 {
    font-size: 8vw;
    margin-bottom: 10vw;
  }

  h3{
    font-size: 8vw;
  }

  p{
    font-size: 4vw;
  }

  // @media all and (min-width: 1300px){
  //   body{
  //     max-width: 1200px !important;
  //   }
  // }

}